<template>
    <div class="color-modal" v-click-outside="onClickOutside">
        <p class="circle-1" :class="{ active: colorStr == '9DE8FF'}" @click="colorStr='9DE8FF'"></p>
        <p class="circle-2" :class="{ active: colorStr == '323DA3'}" @click="colorStr='323DA3'"></p>
        <p class="circle-3" :class="{ active: colorStr == '9F9DFF'}" @click="colorStr='9F9DFF'"></p>
        <p class="circle-4" :class="{ active: colorStr == '7B009A'}" @click="colorStr='7B009A'"></p>
        <p class="circle-5" :class="{ active: colorStr == 'E058B2'}" @click="colorStr='E058B2'"></p>
        <p class="circle-6" :class="{ active: colorStr == '37F1C5'}" @click="colorStr='37F1C5'"></p>
        <p class="circle-7" :class="{ active: colorStr == '37F182'}" @click="colorStr='37F182'"></p>
        <p class="circle-8" :class="{ active: colorStr == 'D2B447'}" @click="colorStr='D2B447'"></p>
        <p class="circle-9" :class="{ active: colorStr == 'D8874D'}" @click="colorStr='D8874D'"></p>
        <p class="circle-10" :class="{ active: colorStr == '9B2020'}" @click="colorStr='9B2020'"></p>
    </div>
</template>
<script>
    import vClickOutside from 'v-click-outside'


    export default {
        props: ['color','updateColor'],
        data() {
            return {
                colorStr: this.color
            }
        },
        watch: {
            colorStr() {
                this.updateColor(this.colorStr)
            }
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        methods: {
            onClickOutside () {
                this.$emit('close')
            }
        }
    }
</script>
<style scoped>

    .status__menu .color-modal{
        top: 50px;
        right: -25px;
    }

    .color-modal{
        width: 160px;
        height: 70px;
        position: absolute;
        background-color: #fff;
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
        border-radius: 2px;
        top: 152px;
        right: 25px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .project-settings__item.color .color-modal{
        top: 113px;
        left: 0px;
        z-index: 2;
    }

    p{
        padding: 0;
        margin: 5px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #eaeaea;
        cursor: pointer;
    }

    .circle-1{
        background: #9DE8FF;
    }

    .circle-2{
        background: #323DA3;
    }

    .circle-3{
        background: #9F9DFF;
    }

    .circle-4{
        background: #7B009A;
    }

    .circle-5{
        background: #E058B2;
    }

    .circle-6{
        background: #37F1C5;
    }

    .circle-7{
        background: #37F182;
    }

    .circle-8{
        background: #D2B44A;
    }

    .circle-9{
        background: #D8874D;
    }

    .circle-10{
        background: #9B2020;
    }

    .active{
        background-image: url('../../assets/img/check_color.svg');
        background-position: center;
        background-repeat: no-repeat;
    }
</style>